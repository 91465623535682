import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Box,
  Avatar,
  ListItemText,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { Group, Delete } from '@material-ui/icons'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'

import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import {
  userDetails,
  UpdateUserDetails,
  successUserDetails,
  modifyUserDetails,
  resetErrorAndSuccessState,
  assignEkeyToUser,
  assignEkeyList,
  deleteAssignedEkey,
  removeUserDevice,
  ekeyList,
  timeZoneList,
  resendInviteLink,
  sendResetLink,
  modifyUserFlagged,
} from '../store/actions/userActions'
import { companyListForUsers } from '../store/actions/companyActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withSnackbar } from 'notistack'
import validator from 'validator'
import { makeStyles, alpha, withStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { format } from 'date-fns'
import { Alert, Autocomplete } from '@material-ui/lab'
import { generateUniqueNumber, getTimes } from '../helper/commonHelper'
import moment from 'moment'
// confirm dialog
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputMask from 'react-input-mask'
import { getCompanySettings } from '../store/actions/companySettingsActions'
import dictionary from '../store/dictionary.json'

const CheckboxWithGreenCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 5,
        top: 5,
        height: 14,
        width: 14,
        position: 'absolute',
        backgroundColor: '#1e4ca1',
        zIndex: 0,
      },
    },
  },
  checked: {},
})(Checkbox)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  primary: {
    main: '#1e4ca1',
  },
  disabledColor: {
    background: '#E8E8E8',
  },
  imgSize: {
    width: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  lockImgSize: {
    cursor: 'pointer',
    width: 25,
    marginRight: 10,
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
    '&:last-child': {
      borderRight: 'solid 1px #cccccc',
    },
  },
  activeSelected: {
    background: '#efefef',
    borderRight: 'solid 1px #cccccc',
  },
}))

const ModifyUser = (props) => {
  const { enqueueSnackbar, error, success, loading, pageLoading } = props
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const { user_Details } = useSelector((state) => state.user)
  const { assigned_ekey_List } = useSelector((state) => state.user)
  const { ekey_List } = useSelector((state) => state.user)
  const { timeZone_List } = useSelector((state) => state.user)
  const { user_Details_Old } = useSelector((state) => state.user)

  const history = useHistory()

  const { groupKeys } = useSelector((state) => state.user)

  const [confirmOpen, setConfirmOpen] = React.useState({
    show: false,
    key: '',
    item: {},
    loadingDelete: false,
  })

  const [userOldDetails, setUserOldDetails] = React.useState(user_Details)

  const ACCESS_TYPE = {
    ONE_TIME_ACCESS: 'one_time_access',
    CUSTOM_ACCESS: 'custom_access',
    FULL_ACCESS: 'full_access',
  }

  // user update functionality
  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }

    if (!user_Details.lastName) {
      isError = true
      formerr.lastName =
        dictionary[localStorage.getItem('language')].last_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!user_Details.firstName) {
      isError = true
      formerr.firstName =
        dictionary[localStorage.getItem('language')].first_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!user_Details.displayName) {
      isError = true
      formerr.displayName =
        dictionary[localStorage.getItem('language')].display_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!user_Details.email) {
      isError = true
      formerr.email =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (user_Details.email && !validator.isEmail(user_Details.email)) {
      isError = true
      formerr.email =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].invalid_tooltip
      setFormValidation(formerr)
    }

    if (!user_Details.countryCode) {
      isError = true
      formerr.countryCode =
        dictionary[localStorage.getItem('language')].country_code_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!user_Details.cell) {
      isError = true
      formerr.cell =
        dictionary[localStorage.getItem('language')].cell_number_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    } else if (user_Details.cell.length < 10) {
      isError = true
      formerr.cell =
        dictionary[localStorage.getItem('language')].cell_number_invalid
      setFormValidation(formerr)
    }

    return isError
  }
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      return (
        phoneNumber.substring(0, 3) +
        '-' +
        phoneNumber.substring(3, 6) +
        '-' +
        phoneNumber.substring(6, 10)
      )
    } else {
      return phoneNumber
    }
  }
  const handleChange = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    dispatch(successUserDetails({ ...user_Details, [prop]: value }))
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
    setUpdateDisabledValue(false)
  }

  const handleBlur = () => {
    const firstName = user_Details.firstName
    const lastName = user_Details.lastName
    const displayName = lastName.concat(', ', firstName)
    const formerr = { ...formValidation }
    formerr.displayName = null
    setFormValidation(formerr)
    dispatch(UpdateUserDetails({ ...user_Details, displayName: displayName }))
  }

  const handleForgotDeviceClick = (e) => {
    dispatch(removeUserDevice(user_Details.id))
  }

  const handleResendInvite = (e) => {
    const data = {
      userId: user_Details.id,
    }
    dispatch(resendInviteLink(data))
  }

  const handleSendResetLink = (e) => {
    const data = {
      userId: user_Details.id,
    }
    dispatch(sendResetLink(data))
  }

  const handleUpdateClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const uData = {
      userId: user_Details.id,
      firstName: user_Details.firstName,
      lastName: user_Details.lastName,
      displayName: user_Details.displayName,
      emailId: user_Details.email,
      countryCode: user_Details.countryCode,
      phoneNumer: user_Details.cell,
      userNotes: user_Details.user_notes,
      isSentinel: user_Details.isSentinel === true ? '1' : '0',
      isSuperAdmin: user_Details.isSuperAdmin === true ? '1' : '0',
      isInstaller: user_Details.isInstaller === true ? '1' : '0',
      isFob: user_Details.isFob === true ? '1' : '0',
      isInactive: user_Details.isInactive === true ? '1' : '0',
      isSOInterrupt: user_Details.isSOInterrupt === true ? '1' : '0',
    }
    dispatch(modifyUserDetails(uData, user_Details_Old))
  }

  const handleUpdateFlagged = (e, flaggedStatus) => {
    e.preventDefault()

    const uData = {
      userId: user_Details.id,
      is_flagged: flaggedStatus,
      support_ticket_id: null,
    }

    dispatch(modifyUserFlagged(uData))
  }

  const showSupportTicketDetails = (e) => {
    e.preventDefault()
    localStorage.setItem('tempSupportId', user_Details.support_ticket_id)
    history.push({
      pathname: '/supportTicketDetails',
    })
  }

  const [formValidation, setFormValidation] = React.useState({
    lastName: '',
    firstName: '',
    displayName: '',
    email: '',
    cell: '',
    countryCode: '',
  })

  const [updateDisabled, setUpdateDisabledValue] = useState(true)

  // assign ekeys functionality
  const [eKeyValue, seteKeyValue] = useState(null)
  const [isGroupSelected, setIsGroupSelected] = useState(false)
  const [timeList, settimeList] = useState([])

  const [values, setValues] = React.useState({
    eKey: '', // device id
    fobDeviceId: '',

    // date and time
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    zone: '',

    isNeverEnd: false,
    isAllDay: false,

    entryId: '',

    // schedule
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    days: '',

    type: 'device',
    groupId: '',
    accessType: ACCESS_TYPE.FULL_ACCESS,
  })

  const [formValidationEkey, setFormValidationEkey] = React.useState({
    eKey: '',
    // fobDeviceId: "",
  })

  const [dispatchStartTime, setDispatchStartTime] = React.useState()
  const [dispatchEndTime, setDispatchEndTime] = React.useState()

  const isEndDateDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    } else if (
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      if (values['isNeverEnd'] == true) {
        return true
      }
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, eKeyValue])

  const isStartDateDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, eKeyValue])

  const isAllDayDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    }
    if (
      isGroupSelected &&
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, isGroupSelected, eKeyValue])

  const isNeverEndDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, eKeyValue])

  const isTimeDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    } else if (
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      if (values['isAllDay'] == true) {
        return true
      }
    }
    if (
      isGroupSelected &&
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, isGroupSelected, eKeyValue])

  const isZoneDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    }
    if (
      isGroupSelected &&
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, isGroupSelected, eKeyValue])

  const isWeekDisabled = useMemo(() => {
    if (values['accessType'] == ACCESS_TYPE.FULL_ACCESS) {
      return true
    }
    if (
      isGroupSelected &&
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values['accessType']
      )
    ) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [values, isGroupSelected, eKeyValue])

  const isFullAccessDisabled = useMemo(() => {
    if (isGroupSelected) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [isGroupSelected, eKeyValue])

  const isCustomAccessDisabled = useMemo(() => {
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [isGroupSelected, eKeyValue])

  const isOneTimeAccessDisabled = useMemo(() => {
    if (isGroupSelected) {
      return true
    }
    // if no ekey is selected, disable
    if (!eKeyValue) {
      return true
    }
    return false
  }, [isGroupSelected, eKeyValue])

  const handleClickAssignEkey = (e) => {
    e.preventDefault()
    if (handleValidationEkey()) return false

    const ekeyData = {
      userId: user_Details.id,
      eKey: values.eKey,
      type: values.type,
      status: values.accessType == ACCESS_TYPE.ONE_TIME_ACCESS ? '2' : '1',
      FOBDeviceId: values.fobDeviceId,
      startDate:
        [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
          values.accessType
        ) && values.startDate
          ? moment(values.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null,
      startTime:
        values.accessType == ACCESS_TYPE.FULL_ACCESS
          ? null
          : [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
              values.accessType
            ) && values.isAllDay == '1'
          ? null
          : values.startTime,
      endDate:
        values.accessType == ACCESS_TYPE.FULL_ACCESS
          ? null
          : [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
              values.accessType
            ) && values.isNeverEnd == '1'
          ? null
          : moment(values.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endTime:
        values.accessType == ACCESS_TYPE.FULL_ACCESS
          ? null
          : [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
              values.accessType
            ) && values.isAllDay == '1'
          ? null
          : values.endTime,

      accessType: values.accessType,
      isNeverEnd:
        [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
          values.accessType
        ) && values.isNeverEnd
          ? '1'
          : '0',
      isAllDay:
        [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
          values.accessType
        ) && values.isAllDay
          ? '1'
          : '0',
      entryId: values.entryId,

      scheduleDays: !(values.accessType == ACCESS_TYPE.FULL_ACCESS)
        ? JSON.stringify({
            Mon: values.mon,
            Tue: values.tue,
            Wed: values.wed,
            Thu: values.thu,
            Fri: values.fri,
            Sat: values.sat,
            Sun: values.sun,
          })
        : null,
      zone: values.zone,
    }

    const uDetail = {
      id: user_Details.id,
      displayName: user_Details.displayName,
      group_id: values.group_id,
      group_name: values.group_name,
    }
    dispatch(assignEkeyToUser(ekeyData, eKeyValue, uDetail, oldKeyDetails))
  }

  const handleValidationEkey = () => {
    let isErrorEkey = false
    const formerrEkey = { ...formValidationEkey }
    if (!values.eKey) {
      isErrorEkey = true
      formerrEkey.eKey =
        dictionary[localStorage.getItem('language')].ekey_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')]
          .required_field_tooltip_feminine
      setFormValidationEkey(formerrEkey)
    }

    if (
      [ACCESS_TYPE.ONE_TIME_ACCESS, ACCESS_TYPE.CUSTOM_ACCESS].includes(
        values.accessType
      )
    ) {
      if (!values.startDate) {
        isErrorEkey = true
        formerrEkey.startDate =
          dictionary[localStorage.getItem('language')].start_date_field_name +
          ' ' +
          dictionary[localStorage.getItem('language')]
            .required_field_tooltip_feminine
        setFormValidationEkey(formerrEkey)
      }

      // if never end is not selected, end date is required
      if (!values.isNeverEnd) {
        if (!values.endDate) {
          isErrorEkey = true
          formerrEkey.endDate =
            dictionary[localStorage.getItem('language')].end_date_field_name +
            ' ' +
            dictionary[localStorage.getItem('language')]
              .required_field_tooltip_feminine
          setFormValidationEkey(formerrEkey)
        }
      }

      // if all day is not selected, start time and end time are required
      if (!values.isAllDay) {
        if (!values.startTime || values.startTime == '--:--') {
          isErrorEkey = true
          formerrEkey.startTime =
            dictionary[localStorage.getItem('language')].start_time_field_name +
            ' ' +
            dictionary[localStorage.getItem('language')].and +
            ' ' +
            dictionary[localStorage.getItem('language')].end_time_field_name +
            ' ' +
            dictionary[localStorage.getItem('language')]
              .required_field_tooltip_feminine
          setFormValidationEkey(formerrEkey)
        }
        if (!values.endTime || values.endDate == '--:--') {
          isErrorEkey = true
          formerrEkey.endTime =
            dictionary[localStorage.getItem('language')].start_time_field_name +
            ' ' +
            dictionary[localStorage.getItem('language')].and +
            ' ' +
            dictionary[localStorage.getItem('language')].end_time_field_name +
            ' ' +
            dictionary[localStorage.getItem('language')]
              .required_field_tooltip_feminine
          setFormValidationEkey(formerrEkey)
        }
      }

      if (!values.zone) {
        isErrorEkey = true
        formerrEkey.zone =
          dictionary[localStorage.getItem('language')].time_zone_field_name +
          ' ' +
          dictionary[localStorage.getItem('language')]
            .required_field_tooltip_feminine
        setFormValidationEkey(formerrEkey)
      }

      if (
        !values.mon &&
        !values.tue &&
        !values.wed &&
        !values.thu &&
        !values.fri &&
        !values.sat &&
        !values.sun
      ) {
        isErrorEkey = true
        formerrEkey.days =
          dictionary[localStorage.getItem('language')].days_error_tooltip
        setFormValidationEkey(formerrEkey)
      }
    }
    return isErrorEkey
  }

  const handleChangeEkey = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    // if is never end is checked, clear the end date
    if (event.target.name == 'isNeverEnd' && value == true) {
      values['endDate'] = null
    }

    if (event.target.name == 'isAllDay' && value == true) {
      values['startTime'] = null
      values['endTime'] = null
      setDispatchStartTime(null)
      setDispatchEndTime(null)
    }

    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidationEkey }
    formerr[event.target.name] = null

    // reset the error for the strt and end time when the never end is checked or unchecked
    if (event.target.name == 'isAllDay') {
      formerr['startTime'] = null
      formerr['endTime'] = null
    }
    // reset the error for end date when the never end is checked or unchekced
    if (event.target.name == 'isNeverEnd') {
      formerr['endDate'] = null
    }

    if (prop == 'accessType') {
      if (value == ACCESS_TYPE.ONE_TIME_ACCESS) {
        // reset everything except entry id
        let scheduleReset = { ...values }
        scheduleReset['accessType'] = value
        scheduleReset['startDate'] = moment().format('YYYY-MM-DD')
        scheduleReset['endDate'] = ''
        scheduleReset['startTime'] = ''
        scheduleReset['endTime'] = ''

        scheduleReset['mon'] = true
        scheduleReset['tue'] = true
        scheduleReset['wed'] = true
        scheduleReset['thu'] = true
        scheduleReset['fri'] = true
        scheduleReset['sat'] = true
        scheduleReset['sun'] = true

        scheduleReset['isNeverEnd'] = true
        scheduleReset['isAllDay'] = true
        setValues(scheduleReset)
      } else if (value == ACCESS_TYPE.FULL_ACCESS) {
        // reset everything except entry id
        let scheduleReset = { ...values }
        scheduleReset['accessType'] = value
        scheduleReset['mon'] = false
        scheduleReset['tue'] = false
        scheduleReset['wed'] = false
        scheduleReset['thu'] = false
        scheduleReset['fri'] = false
        scheduleReset['sat'] = false
        scheduleReset['sun'] = false
        scheduleReset['startDate'] = ''
        scheduleReset['endDate'] = ''
        scheduleReset['startTime'] = ''
        scheduleReset['endTime'] = ''

        scheduleReset['isNeverEnd'] = ''
        scheduleReset['isAllDay'] = ''

        scheduleReset['zone'] = ''
        setValues(scheduleReset)
        setDispatchStartTime(null)
        setDispatchEndTime(null)

        formerr['startDate'] = null
        formerr['startTime'] = null
        formerr['endDate'] = null
        formerr['endTime'] = null
        formerr['zone'] = null
        formerr['days'] = null
      } else if (value == ACCESS_TYPE.CUSTOM_ACCESS) {
        let scheduleReset = { ...values }
        scheduleReset['accessType'] = value
        scheduleReset['startDate'] = moment().format('YYYY-MM-DD')
        scheduleReset['endDate'] = ''
        scheduleReset['startTime'] = ''
        scheduleReset['endTime'] = ''

        scheduleReset['mon'] = true
        scheduleReset['tue'] = true
        scheduleReset['wed'] = true
        scheduleReset['thu'] = true
        scheduleReset['fri'] = true
        scheduleReset['sat'] = true
        scheduleReset['sun'] = true

        scheduleReset['isNeverEnd'] = true
        scheduleReset['isAllDay'] = true
        setValues(scheduleReset)
      }
    }

    if (
      prop == 'mon' ||
      prop == 'tue' ||
      prop == 'wed' ||
      prop == 'thu' ||
      prop == 'fri' ||
      prop == 'sat' ||
      prop == 'sun'
    ) {
      formerr['days'] = null
    }
    setFormValidationEkey(formerr)
  }

  useEffect(() => {
    const userId = localStorage.getItem('tempId')
    if (!userId) {
      history.push({
        pathname: '/users',
      })
    } else {
      const isLoaderReq =
        location.isLoaderRequired != undefined
          ? location.isLoaderRequired
          : true
      dispatch(userDetails(userId, isLoaderReq))
      dispatch(assignEkeyList(userId))
      //dispatch(companyListForUsers())
    }
    dispatch(ekeyList('group', userId, false))
    dispatch(timeZoneList())
    dispatch(getCompanySettings())
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
    dispatch(resetErrorAndSuccessState())
  }, [error])

  useEffect(() => {
    success && enqueueSnackbar(success, { variant: 'success' })
    dispatch(resetErrorAndSuccessState())
    const userId = localStorage.getItem('tempId')
    if (!userId) {
      history.push({
        pathname: '/users',
      })
    } else if (
      success === 'eKeys/Groups Assigned successfully' ||
      success === 'eKeys Modified successfully' ||
      success === 'ekey removed successfully'
    ) {
      dispatch(assignEkeyList(userId))
      dispatch(ekeyList('group', userId, false))
      resetEkayAssignForm()
      handleClose()
    }
  }, [success])

  useEffect(() => {
    settimeList(getTimes())
  }, [getTimes])

  const resetEkayAssignForm = () => {
    var resetForm = { ...values }
    resetForm['eKey'] = ''
    resetForm['fobDeviceId'] = ''
    resetForm['accessType'] = ACCESS_TYPE.FULL_ACCESS
    resetForm['startDate'] = ''
    resetForm['endDate'] = ''
    resetForm['startTime'] = ''
    resetForm['endTime'] = ''

    resetForm['isNeverEnd'] = ''
    resetForm['isAllDay'] = ''

    resetForm['zone'] = ''
    resetForm['mon'] = false
    resetForm['tue'] = false
    resetForm['wed'] = false
    resetForm['thu'] = false
    resetForm['fri'] = false
    resetForm['sat'] = false
    resetForm['sun'] = false
    resetForm['entryId'] = ''
    setDispatchStartTime(null)
    setDispatchEndTime(null)
    setValues(resetForm)

    seteKeyValue(null)
    setIsGroupSelected(false)
  }

  const handleChangeSelection = (event, type) => {
    if (event) {
      // set device/group details
      if (type === 'ekey') {
        seteKeyValue(event)
        var vData = { ...values }
        vData['eKey'] = event.id

        if (event.type === 'group') {
          vData['type'] = 'group'
          vData['zone'] = event.time_zone_id ? event.time_zone_id : ''
          vData['startTime'] = event.start_at
          vData['endTime'] = event.end_at
          vData['mon'] = event.sch_monday
          vData['tue'] = event.sch_tuesday
          vData['wed'] = event.sch_wednesday
          vData['thu'] = event.sch_thursday
          vData['fri'] = event.sch_friday
          vData['sat'] = event.sch_saturday
          vData['sun'] = event.sch_sunday
          vData['isAllDay'] = event.is_all_day == '1'
          vData['isNeverEnd'] = true
          vData['accessType'] = ACCESS_TYPE.CUSTOM_ACCESS
          vData['startDate'] = moment().format('yyyy-MM-DD')
          vData['endDate'] = null
          vData['group_id'] = event.id
          vData['group_name'] = event.full_name

          if (event.start_at) {
            const StartTime = event.start_at.split(':')
            const EndTime = event.end_at.split(':')
            setDispatchStartTime(
              moment().hour(StartTime[0]).minute(StartTime[1])
            )
            setDispatchEndTime(moment().hour(EndTime[0]).minute(EndTime[1]))
          } else {
            setDispatchStartTime(null)
            setDispatchEndTime(null)
          }

          setIsGroupSelected(true)
        } else {
          vData['type'] = 'device'
          // vData["zone"] = ""
          // vData["startTime"] = ""
          // vData["endTime"] = ""
          // vData["mon"] = false
          // vData["tue"] = false
          // vData["wed"] = false
          // vData["thu"] = false
          // vData["fri"] = false
          // vData["sat"] = false
          // vData["sun"] = false

          setIsGroupSelected(false)
        }

        setValues(vData)

        // reset error messages
        const formerr = { ...formValidationEkey }
        formerr['eKey'] = null
        formerr['startDate'] = null
        formerr['startTime'] = null
        formerr['endDate'] = null
        formerr['endTime'] = null
        formerr['zone'] = null
        formerr['days'] = null
        setFormValidationEkey(formerr)
      } else if (type === 'zone') {
        setFormValidationEkey({ ...formValidationEkey, zone: null })
        setValues({ ...values, zone: event })
      } else if (type === 'startTime') {
        setFormValidationEkey({ ...formValidationEkey, startTime: null })
        setValues({ ...values, startTime: event })
      } else if (type === 'endTime') {
        setFormValidationEkey({ ...formValidationEkey, endTime: null })
        setValues({ ...values, endTime: event })
      }
    }
  }

  const setDefaultTimeZone = (state) => {
    if (
      // !defaultCountryCodeSet &&
      !values.zone &&
      state.companySettings?.companySettingsDetails?.default_time_zone_id
    ) {
      // updateDefaultCountryCodeSet(true);
      setValues({
        ...values,
        zone: state.companySettings?.companySettingsDetails
          ?.default_time_zone_id,
      })
    }
  }

  const { companySettingsDetails } = useSelector((state) => {
    setDefaultTimeZone(state)
    return state.companySettings
  })

  const removeEkeyFromUser = () => {
    if (confirmOpen.item) {
      setConfirmOpen({ ...confirmOpen, loadingDelete: true })
      const item = confirmOpen.item
      const uDetail = {
        id: user_Details.id,
        displayName: user_Details.displayName,
      }
      dispatch(deleteAssignedEkey(item.id, item, uDetail))
    }
  }

  const handleChangeSchedule = (event, type) => {
    if (event) {
      setFormValidationEkey({ ...formValidationEkey, [type]: null })

      if (type === 'startDate') {
        setValues({ ...values, startDate: event })
      } else if (type === 'endDate') {
        setValues({ ...values, endDate: event })
      } else if (type === 'startTime') {
        setValues({ ...values, startTime: event })
      } else if (type === 'endTime') {
        setValues({ ...values, endTime: event })
      }
    }
  }

  const handleChangeCheckboxValue = (key, value) => {
    // dispatch(successUserDetails({ ...user_Details, [key]: value }))
    if (key === 'isSuperAdmin' && value) {
      dispatch(successUserDetails({ ...user_Details, [key]: value, 'isSentinel': true }))
    } else {
      dispatch(successUserDetails({ ...user_Details, [key]: value }))
    }

    setUpdateDisabledValue(false)
  }

  const [oldKeyDetails, setOldKeyDetails] = React.useState({})

  const parseDate = (input) => {
    if (input) {
      const parts = input.split('-')
      return new Date(parts[0], parts[1] - 1, parts[2]) // Note: months are 0-based
    } else {
      return input
    }
  }

  /**
   * This function is called when we select any ekey from the user assigned ekeys lists
   * @param {*} selectedEkey
   */
  const handleAssignedEkeyInfoClick = (selectedEkey) => {
    // diselect if already selected
    // RESET THE FORM (DESELECT EKEY)
    // if the currently selected ekey is selected again,
    // remove that ekey selection and reset the form so that
    // it becomes the clean state without any ekey selected from the list
    if (values.entryId == selectedEkey.entry_id) {
      const userId = localStorage.getItem('tempId')
      dispatch(assignEkeyList(userId)) // user's assigned ekey list update
      dispatch(ekeyList('group', userId, false)) // update device/device group list
      resetEkayAssignForm() // reset the form
      handleClose() // delete popup handle close (?)
    } else {
      // select the ekey and load into the form

      // When the ekey clicked is individual ekey
      if (!selectedEkey.is_group) {
        // New Ekey is selected, Load all the values of the selected ekey in the form
        setOldKeyDetails(selectedEkey) // copy current ekey data as the old data
        resetEkayAssignForm() // reset the form

        // set start time and end time
        if (selectedEkey.start_time) {
          const StartTime = selectedEkey.start_time.split(':')
          const EndTime = selectedEkey.end_time.split(':')
          setDispatchStartTime(moment().hour(StartTime[0]).minute(StartTime[1]))
          setDispatchEndTime(moment().hour(EndTime[0]).minute(EndTime[1]))
        } else {
          setDispatchStartTime(null)
          setDispatchEndTime(null)
        }

        var vData = { ...values }
        vData['eKey'] = selectedEkey.ekeyDetails.id
        vData['zone'] = selectedEkey.time_zone_id
        vData['startDate'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : parseDate(selectedEkey.start_date)
        vData['endDate'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : parseDate(selectedEkey.end_date)
        vData['startTime'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : selectedEkey.start_time
        vData['endTime'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : selectedEkey.end_time
        vData['mon'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Mon
            : false
        vData['tue'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Tue
            : false
        vData['wed'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Wed
            : false
        vData['thu'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Thu
            : false
        vData['fri'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Fri
            : false
        vData['sat'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Sat
            : false
        vData['sun'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Sun
            : false
        vData['accessType'] = selectedEkey.access_type
        vData['isNeverEnd'] = selectedEkey.is_never_end == '1'
        vData['isAllDay'] = selectedEkey.is_all_day == '1'
        vData['entryId'] = selectedEkey.entry_id
        vData['group_id'] = ''
        vData['type'] = 'device'
        setValues(vData)

        // set key in dropdown
        seteKeyValue(selectedEkey.ekeyDetails)
      } else {
        // When the ekey clicked is the group Ekey

        // New Ekey is selected, Load all the values of the selected ekey in the form
        setOldKeyDetails(selectedEkey) // copy current ekey data as the old data
        resetEkayAssignForm() // reset the form

        // set start time and end time
        if (selectedEkey.start_time) {
          const StartTime = selectedEkey.start_time.split(':')
          const EndTime = selectedEkey.end_time.split(':')
          setDispatchStartTime(moment().hour(StartTime[0]).minute(StartTime[1]))
          setDispatchEndTime(moment().hour(EndTime[0]).minute(EndTime[1]))
        } else {
          setDispatchStartTime(null)
          setDispatchEndTime(null)
        }

        var vData = { ...values }
        vData['eKey'] = selectedEkey.deviceGroup.id
        vData['zone'] = selectedEkey.time_zone_id
        vData['startDate'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : parseDate(selectedEkey.start_date)
        vData['endDate'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : parseDate(selectedEkey.end_date)
        vData['startTime'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : selectedEkey.start_time
        vData['endTime'] =
          selectedEkey.access_type == ACCESS_TYPE.FULL_ACCESS
            ? ''
            : selectedEkey.end_time
        vData['mon'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Mon
            : false
        vData['tue'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Tue
            : false
        vData['wed'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Wed
            : false
        vData['thu'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Thu
            : false
        vData['fri'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Fri
            : false
        vData['sat'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Sat
            : false
        vData['sun'] =
          selectedEkey.schedule_days != null
            ? selectedEkey.schedule_days.Sun
            : false
        vData['accessType'] = selectedEkey.access_type
        vData['isNeverEnd'] = selectedEkey.is_never_end == '1'
        vData['isAllDay'] = selectedEkey.is_all_day == '1'
        vData['entryId'] = selectedEkey.entry_id
        vData['group_id'] = selectedEkey.group_id
        vData['type'] = 'group'

        setValues(vData)

        setIsGroupSelected(true)

        // set key in dropdown
        seteKeyValue(selectedEkey.deviceGroup)
      }
    }
  }

  // confirm dialog
  const handleClose = () => {
    setConfirmOpen({
      show: false,
      key: '',
      item: {},
      loadingDelete: false,
    })
  }

  const handleClickOpen = (item) => {
    if (item.is_group) {
      var names = item.deviceGroup.full_name
    } else {
      var names = item.ekeyDetails.full_name
    }
    setConfirmOpen({
      show: true,
      key: names,
      item: item,
      loadingDelete: false,
    })
  }

  const handleValueChange = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      setValues({ ...values, startTime: val })
    }
    setDispatchStartTime(value)
  }

  const handleValueChange1 = (value) => {
    if (value) {
      var val = value.format('HH:mm')
      setValues({ ...values, endTime: val })
    }
    setDispatchEndTime(value)
  }

  const fieldSetStyle = {
    minWidth: 'auto',
    margin: 'initial',
    padding: '8px',
    border: '2px solid #1E4CA1',
    borderRadius: '20px',
  }
  const fieldSetLegendStyle = {
    fontSize: '0.7rem',
    lineHeight: 'inherit',
    display: 'inherit',
    width: 'inherit',
    maxWidth: 'inherit',
    marginBottom: 'inherit',
    padding: '5px',
    whiteSpace: 'inherit',
    color: 'inherit',
    textAlign: 'center',
    color: 'var(--primary)',
    fontWeight: 'bold',
    margin: 'auto',
  }

  const grouped_ekey_List = ekey_List.map((option) => {
    const groupBy_Label = option.type == 'group' ? 'Device Group' : 'Device'
    return {
      groupByLabel: groupBy_Label,
      ...option,
    }
  })

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              {user_Details?.is_flagged === '1' && (
                <div style={{ overflowX: 'hidden' }}>
                  <div className="row align-items-center justify-content-center">
                    <Alert variant="filled" severity="error" icon={false}>
                      <div
                        className="col"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'baseline',
                        }}
                      >
                        <p
                          className="font-weight-600"
                          style={{ marginBottom: '0px' }}
                        >
                          {
                            dictionary[localStorage.getItem('language')]
                              .user_flagged
                          }
                        </p>
                        <div style={{ marginLeft: '25px' }}>
                          <div className="row">
                            <div className="col">
                              {user_Details.support_ticket_id ? (
                                <Button
                                  className="btn btn-primary"
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={(e) => showSupportTicketDetails(e)}
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .user_flagged_details
                                  }
                                </Button>
                              ) : null}
                            </div>
                            <div className="col">
                              <Button
                                className="btn btn-primary"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={(e) => handleUpdateFlagged(e, '0')}
                                style={{ width: '106%' }}
                              >
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .user_flagged_approve
                                }
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Alert>
                  </div>
                </div>
              )}
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-6"></div>
                <div
                  className="col-lg-6"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {user_Details?.accountSetupPending ? (
                    <Button
                      className="btn btn-primary"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleResendInvite}
                      // style={{ display: props.loading ? 'none' : 'block',textTransform: 'none' }}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .resend_invite_button
                      }
                    </Button>
                  ) : null}
                  {!companySettingsDetails?.is_multi_phone_login ? (
                    <Button
                      className="btn btn-primary"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleForgotDeviceClick}
                      // style={{ display: props.loading ? 'none' : 'block',textTransform: 'none' }}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .forget_user_device_button
                      }
                    </Button>
                  ) : null}

                  {companySettingsDetails?.password_reset_security ===
                  'admin' ? (
                    <Button
                      className="btn btn-primary"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleSendResetLink}
                      // style={{ display: props.loading ? 'none' : 'block',textTransform: 'none' }}
                    >
                      {
                        dictionary[localStorage.getItem('language')]
                          .send_password_reset_button
                      }
                    </Button>
                  ) : null}

                  <Link className="btn btn-primary" to="/users">
                    {
                      dictionary[localStorage.getItem('language')]
                        .user_list_button
                    }
                  </Link>
                </div>
              </div>
              <div className="card-header">
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        id="lname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .last_name_field_name
                        }
                        variant="outlined"
                        name="lastName"
                        value={user_Details.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur}
                        error={!!formValidation.lastName}
                        helperText={formValidation.lastName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="fname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .first_name_field_name
                        }
                        variant="outlined"
                        name="firstName"
                        value={user_Details.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur}
                        error={!!formValidation.firstName}
                        helperText={formValidation.firstName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="dname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .display_name_field_name
                        }
                        variant="outlined"
                        name="displayName"
                        value={user_Details.displayName}
                        onChange={handleChange('displayName')}
                        error={!!formValidation.displayName}
                        helperText={formValidation.displayName}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <div class="row">
                        <div className="col-md-6">
                          <TextField
                            id="email"
                            fullWidth
                            label={
                              dictionary[localStorage.getItem('language')]
                                .email_field_name
                            }
                            variant="outlined"
                            name="email"
                            value={user_Details.email}
                            onChange={handleChange('email')}
                            error={!!formValidation.email}
                            helperText={formValidation.email}
                            // disabled={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4">
                              <TextField
                                id="countryCode"
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .country_code_field_name
                                }
                                variant="outlined"
                                name="countryCode"
                                value={user_Details.countryCode}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                    /^[0-9\b]+$/.test(e.target.value)
                                  ) {
                                    handleChange('countryCode')(e)
                                  }
                                }}
                                error={!!formValidation.countryCode}
                                helperText={formValidation.countryCode}
                                inputProps={{
                                  inputMode: 'numeric',
                                  maxLength: 3,
                                  pattern: '[0-9]{1,3}',
                                }}
                                // disabled={true}
                              />
                            </div>
                            <div className="col-md-8">
                              <InputMask
                                mask="999-999-9999"
                                value={user_Details.cell}
                                onChange={handleChange('cell')}
                              >
                                {(inputProps) => (
                                  <TextField
                                    {...inputProps}
                                    id="cell"
                                    fullWidth
                                    label={
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].cell_number_field_name
                                    }
                                    variant="outlined"
                                    name="cell"
                                    // value={formatPhoneNumber(user_Details.cell)}
                                    // onChange={handleChange("cell")}
                                    error={!!formValidation.cell}
                                    helperText={formValidation.cell}
                                    // disabled={true}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <TextField
                            id="usernote"
                            fullWidth
                            label={
                              dictionary[localStorage.getItem('language')]
                                .user_notes_field_name
                            }
                            variant="outlined"
                            name="user_notes"
                            value={user_Details.user_notes}
                            onChange={handleChange('user_notes')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div
                          className="col-md-7"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <fieldset style={fieldSetStyle}>
                            <legend style={fieldSetLegendStyle}>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .additional_privileges_header
                              }
                            </legend>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].schedule_open_interrupt_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {user_Details.isSOInterrupt ? (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSOInterrupt',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSOInterrupt',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .schedule_open_interrupt_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].installer_permissions_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {user_Details.isInstaller ? (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isInstaller',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isInstaller',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .installer_permissions_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].sentinel_web_management_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {user_Details.isSentinel ? (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSentinel',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSentinel',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .sentinel_web_management_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].super_admin_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {user_Details.isSuperAdmin ? (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSuperAdmin',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSuperAdmin',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .super_admin_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                className="mb-2"
                              >
                                <a
                                  href="https://support.pac-blu.com/permissions/"
                                  target="_blank"
                                  style={{ textDecoration: 'underline #1e4ca1', fontSize: 'smaller' }}
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .learn_more_permissions_option
                                  }
                                </a>
                              </Typography>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-md-5">
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <fieldset
                              style={{ ...fieldSetStyle, marginBottom: '10px' }}
                            >
                              <legend style={fieldSetLegendStyle}>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .deactivate_account_option
                                }
                              </legend>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '-10px',
                                }}
                              >
                                <Switch
                                  style={{ margin: 'auto' }}
                                  color="primary"
                                  checked={user_Details.isInactive}
                                  inputProps={{ 'aria-label': 'primary' }}
                                  name="is_deactive"
                                  onChange={handleChange('isInactive')}
                                />
                              </div>
                            </fieldset>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={handleUpdateClick}
                                disabled={updateDisabled}
                                style={{
                                  display: props.loading ? 'none' : 'block',
                                }}
                              >
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .save_changes_button
                                }
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                style={{
                                  display: props.loading ? 'block' : 'none',
                                }}
                              >
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .save_changes_button
                                }{' '}
                                &nbsp;
                                <i className="fa fa-spinner fa-spin right" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <Divider
                variant="middle"
                style={{ background: '#1E4CA1', margin: '0 15px 0 15px' }}
              />
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      className="mb-3 text-center"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .assigned_ekeys_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ height: '100%', border: '2px solid #1e4ca1' }}
                    >
                      <CardContent>
                        <List style={{ overflow: 'auto' }}>
                          {assigned_ekey_List.length > 0 ? (
                            assigned_ekey_List.map((item, i) => (
                              <div key={Math.random()}>
                                <ListItem
                                  className={`${
                                    !item.is_group ? classes.active : null
                                  } 
                                ${
                                  item.entry_id == values.entryId
                                    ? classes.activeSelected
                                    : null
                                }`}
                                  onClick={() =>
                                    handleAssignedEkeyInfoClick(item)
                                  }
                                >
                                  <Tooltip title={item.ekeyStatus.title} row>
                                    <img
                                      alt={item.ekeyStatus.title}
                                      src={`../assets/img/${item.ekeyStatus.img}`}
                                      className={classes.lockImgSize}
                                    />
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      item.entry_id == values.entryId
                                        ? dictionary[
                                            localStorage.getItem('language')
                                          ].click_to_unselect_tooltip
                                        : dictionary[
                                            localStorage.getItem('language')
                                          ].click_to_select_tooltip
                                    }
                                    arrow
                                  >
                                    <ListItemText
                                      primary={item.ekeyDetails.full_name}
                                    />
                                  </Tooltip>

                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() => handleClickOpen(item)}
                                    >
                                      {/* onClick={() => removeEkeyFromUser(item)} */}
                                      <Delete />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                              </div>
                            ))
                          ) : assigned_ekey_List.length == 0 &&
                            groupKeys.length == 0 ? (
                            <Typography
                              variant="subtitle2"
                              style={{
                                textAlign: 'center',
                                marginTop: 40 + '%',
                              }}
                              gutterBottom
                              className="mb-3"
                            >
                              {
                                dictionary[localStorage.getItem('language')]
                                  .no_ekeys
                              }
                            </Typography>
                          ) : (
                            ''
                          )}

                          {groupKeys.length > 0 ? (
                            groupKeys.map((item, i) => (
                              <div key={Math.random()}>
                                <ListItem
                                  className={`${
                                    !item.is_group
                                      ? classes.active
                                      : classes.active
                                  } 
                                ${
                                  item.entry_id == values.entryId
                                    ? classes.activeSelected
                                    : null
                                }`}
                                  onClick={() =>
                                    handleAssignedEkeyInfoClick(item)
                                  }
                                >
                                  <Tooltip
                                    title={item.deviceGroup.full_name}
                                    row
                                  >
                                    <img
                                      alt={item.ekeyStatus.title}
                                      src={`../assets/img/${item.ekeyStatus.img}`}
                                      className={classes.lockImgSize}
                                    />
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      item.entry_id == values.entryId
                                        ? dictionary[
                                            localStorage.getItem('language')
                                          ].click_to_unselect_tooltip
                                        : dictionary[
                                            localStorage.getItem('language')
                                          ].click_to_select_tooltip
                                    }
                                    arrow
                                  >
                                    <ListItemText
                                      primary={item.deviceGroup.full_name}
                                    />
                                  </Tooltip>

                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() => handleClickOpen(item)}
                                    >
                                      {/* onClick={() => removeEkeyFromUser(item)} */}
                                      <Delete />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                              </div>
                            ))
                          ) : 1 == 2 ? (
                            <Typography
                              variant="subtitle2"
                              style={{
                                textAlign: 'center',
                                marginTop: 40 + '%',
                              }}
                              gutterBottom
                              className="mb-3"
                            >
                              dictionary[ localStorage.getItem('language')
                              ].no_ekeys
                            </Typography>
                          ) : (
                            ''
                          )}
                        </List>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-md-8">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      className="mb-3 text-center"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .modify_ekeys_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #1e4ca1' }}
                    >
                      <CardContent>
                        <RadioGroup
                          aria-label="accessType"
                          name="accessType"
                          value={values.accessType}
                          onChange={handleChangeEkey('accessType')}
                        >
                          <div className="row">
                            <div className="col">
                              <FormControl
                                variant="outlined"
                                fullWidth
                                error={!!formValidationEkey.eKey}
                              >
                                <Autocomplete
                                  id="combo-box-demo"
                                  value={eKeyValue}
                                  onChange={(event, value) =>
                                    handleChangeSelection(value, 'ekey')
                                  }
                                  options={grouped_ekey_List}
                                  groupBy={(option) => option.groupByLabel}
                                  getOptionLabel={(option) => option.full_name}
                                  renderOption={(option) => (
                                    <div style={{ display: 'flex' }}>
                                      <img
                                        alt=""
                                        src={
                                          option.type == 'group'
                                            ? `../assets/img/lock-group.png`
                                            : `../assets/img/lock.png`
                                        }
                                        className={classes.lockImgSize}
                                      />
                                      <span variant="subtitle2">
                                        {option.full_name}
                                      </span>
                                    </div>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        dictionary[
                                          localStorage.getItem('language')
                                        ].device_selection
                                      }
                                      variant="outlined"
                                      error={!!formValidationEkey.eKey}
                                    />
                                  )}
                                />
                                <FormHelperText>
                                  {formValidationEkey.eKey}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          </div>

                          <div
                            className="mt-2"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginBottom: '-1rem',
                            }}
                          >
                            <div style={{ flex: '1', marginRight: -5 }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  value={ACCESS_TYPE.FULL_ACCESS}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={isFullAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .full_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  value={ACCESS_TYPE.CUSTOM_ACCESS}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={isCustomAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .custom_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  value={ACCESS_TYPE.ONE_TIME_ACCESS}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={isOneTimeAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .one_time_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                          </div>

                          <Divider
                            className="mb-2"
                            style={{ background: '#1E4CA1' }}
                          />

                          <div className="row mt-2 mb-2">
                            <div className="col-md-12">
                              <div className="row pl-2 mr-3 mb-4">
                                <div className="col-md-12">
                                  <div class="row">
                                    <div className="col-md-7">
                                      <Box display="flex">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].start_date_field_name
                                            }
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            value={
                                              values.startDate
                                                ? values.startDate
                                                : null
                                            }
                                            size="small"
                                            name="startDate"
                                            error={
                                              !!formValidationEkey.startDate
                                            }
                                            helperText={
                                              formValidationEkey.startDate
                                            }
                                            disabled={isStartDateDisabled}
                                            InputProps={
                                              isStartDateDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                            onChange={(value) =>
                                              handleChangeSchedule(
                                                value,
                                                'startDate'
                                              )
                                            }
                                            className="mr-2"
                                          />

                                          <DatePicker
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].end_date_field_name
                                            }
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            value={
                                              values.endDate
                                                ? values.endDate
                                                : null
                                            }
                                            size="small"
                                            name="endDate"
                                            error={!!formValidationEkey.endDate}
                                            helperText={
                                              formValidationEkey.endDate
                                            }
                                            disabled={isEndDateDisabled}
                                            InputProps={
                                              isEndDateDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                            onChange={(value) =>
                                              handleChangeSchedule(
                                                value,
                                                'endDate'
                                              )
                                            }
                                          />
                                        </MuiPickersUtilsProvider>
                                        <Box display="flex">
                                          <FormControlLabel
                                            control={
                                              <CheckboxWithGreenCheck
                                                name="isNeverEnd"
                                                color="primary"
                                                checked={values.isNeverEnd}
                                              />
                                            }
                                            value={values.isNeverEnd}
                                            style={{
                                              marginLeft: 0,
                                              marginRight: -5,
                                              marginBottom: 0,
                                              marginTop: 0,
                                            }}
                                            onChange={handleChangeEkey(
                                              'isNeverEnd'
                                            )}
                                            disabled={isNeverEndDisabled}
                                            InputProps={
                                              isNeverEndDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                          />
                                          <Typography
                                            variant="subtitle2"
                                            className="mr-4"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                whiteSpace: 'nowrap',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <strong>
                                                {
                                                  dictionary[
                                                    localStorage.getItem(
                                                      'language'
                                                    )
                                                  ].never_expires_option
                                                }
                                              </strong>
                                            </span>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-4 ml-3">
                                  <div class="row">
                                    <FormControl
                                      component="fieldset"
                                      style={{ marginTop: -9 }}
                                      error={!!formValidationEkey.days}
                                    >
                                      <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        className="radioGroup"
                                      >
                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].monday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="mon"
                                              color="primary"
                                              checked={values.mon}
                                            />
                                          }
                                          value={values.mon}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('mon')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].tuesday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="tue"
                                              color="primary"
                                              checked={values.tue}
                                            />
                                          }
                                          value={values.tue}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('tue')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].wednesday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="wed"
                                              color="primary"
                                              checked={values.wed}
                                            />
                                          }
                                          value={values.wed}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('wed')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].thursday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="thu"
                                              color="primary"
                                              checked={values.thu}
                                            />
                                          }
                                          value={values.thu}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('thu')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].friday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="fri"
                                              color="primary"
                                              checked={values.fri}
                                            />
                                          }
                                          value={values.fri}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('fri')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].saturday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="sat"
                                              color="primary"
                                              checked={values.sat}
                                            />
                                          }
                                          value={values.sat}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('sat')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].sunday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="sun"
                                              color="primary"
                                              checked={values.sun}
                                            />
                                          }
                                          value={values.sun}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          onChange={handleChangeEkey('sun')}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />
                                      </RadioGroup>
                                      <FormHelperText>
                                        {formValidationEkey.days}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2 ml-1">
                                  <Box display="flex">
                                    <Box display="flex">
                                      <FormControlLabel
                                        control={
                                          <CheckboxWithGreenCheck
                                            name="isAllDay"
                                            color="primary"
                                            checked={values.isAllDay}
                                          />
                                        }
                                        value={values.isAllDay}
                                        style={{
                                          marginLeft: -10,
                                          marginRight: -5,
                                          marginBottom: 0,
                                          marginTop: 0,
                                        }}
                                        onChange={handleChangeEkey('isAllDay')}
                                        disabled={isAllDayDisabled}
                                        InputProps={
                                          isAllDayDisabled
                                            ? {
                                                className:
                                                  classes.disabledColor,
                                              }
                                            : null
                                        }
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        className="mr-4"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <span
                                          style={{
                                            whiteSpace: 'nowrap',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].all_day_option
                                            }
                                          </strong>
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="inline-flex"
                                      flexDirection="column"
                                      className="mr-2"
                                    >
                                      <Box display="flex">
                                        <TimePicker
                                          placeholder={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].start_time_field_name
                                          }
                                          showSecond={false}
                                          focusOnOpen={true}
                                          format="HH:mm"
                                          value={dispatchStartTime}
                                          onChange={handleValueChange}
                                          disabled={isTimeDisabled}
                                          clearText="Clear"
                                          className="mr-2"
                                        />
                                        <TimePicker
                                          placeholder={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].end_time_field_name
                                          }
                                          showSecond={false}
                                          focusOnOpen={true}
                                          format="HH:mm"
                                          onChange={handleValueChange1}
                                          value={dispatchEndTime}
                                          disabled={isTimeDisabled}
                                          clearText="Clear"
                                        />
                                      </Box>
                                      <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={
                                          !!(
                                            formValidationEkey.startTime ||
                                            formValidationEkey.endTime
                                          )
                                        }
                                      >
                                        <FormHelperText>
                                          {formValidationEkey.startTime}
                                        </FormHelperText>
                                      </FormControl>
                                    </Box>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      error={!!formValidationEkey.zone}
                                    >
                                      <Autocomplete
                                        disabled={isZoneDisabled}
                                        className={
                                          isZoneDisabled
                                            ? classes.disabledColor
                                            : null
                                        }
                                        size="small"
                                        style={{ width: 270 }}
                                        id="combo-box-demo"
                                        value={values.zone}
                                        onChange={(event, val) =>
                                          handleChangeSelection(val, 'zone')
                                        }
                                        options={timeZone_List.map(
                                          (it) => it.id
                                        )}
                                        getOptionLabel={(option) =>
                                          timeZone_List.find(
                                            (it) => it.id === option
                                          )?.full_time_zone
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].time_zone_field_name
                                            }
                                            variant="outlined"
                                            error={!!formValidationEkey.zone}
                                          />
                                        )}
                                      />
                                      <FormHelperText>
                                        {formValidationEkey.zone}
                                      </FormHelperText>
                                    </FormControl>
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Divider
                            className="mb-2"
                            style={{ background: '#1E4CA1' }}
                          />

                          <div className="mt-2">
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={handleClickAssignEkey}
                              style={{
                                display: props.loadingKeyAssign
                                  ? 'none'
                                  : 'block',
                              }}
                            >
                              {values.entryId == ''
                                ? dictionary[localStorage.getItem('language')]
                                    .assign_ekey_button
                                : dictionary[localStorage.getItem('language')]
                                    .modify_ekey_button}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              style={{
                                display: props.loadingKeyAssign
                                  ? 'block'
                                  : 'none',
                              }}
                            >
                              {values.entryId == ''
                                ? dictionary[localStorage.getItem('language')]
                                    .assign_ekey_button
                                : dictionary[localStorage.getItem('language')]
                                    .modify_ekey_button}
                              &nbsp;
                              <i className="fa fa-spinner fa-spin right" />
                            </Button>
                          </div>
                        </RadioGroup>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        {/* confirm diaload */}
        <Dialog
          open={confirmOpen.show}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dictionary[localStorage.getItem('language')].delete_ekey_title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dictionary[localStorage.getItem('language')]
                .delete_ekey_subtitle +
                ' ' +
                confirmOpen.key}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color=""
              variant="contained"
              size="small"
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_cancel_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={removeEkeyFromUser}
              color="secondary"
              autoFocus
              style={{ display: confirmOpen.loadingDelete ? 'none' : 'block' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_delete_option
              }
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              autoFocus
              style={{ display: confirmOpen.loadingDelete ? 'block' : 'none' }}
            >
              {
                dictionary[localStorage.getItem('language')]
                  .delete_ekey_delete_option
              }{' '}
              &nbsp;
              <i className="fa fa-spinner fa-spin right" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    loadingKeyAssign: state.user.loadingKeyAssign,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

ModifyUser.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  // onAuth: PropTypes.func.isRequired,
  // error: PropTypes.string.isRequired
}

// export default (withSnackbar(AddUsers));
export default connect(mapStateToProps)(withSnackbar(ModifyUser))
