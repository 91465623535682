import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardContent,
  List,
  Box,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  registerUser,
  UpdateUserDetails,
  userList,
} from '../store/actions/userActions'
import { companyListForUsers } from '../store/actions/companyActions'
import { withSnackbar } from 'notistack'
import validator from 'validator'
import DateFnsUtils from '@date-io/date-fns'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Loader from '../PageLoader'
import Footer from '../footer'
import Submenu from '../header_sub_menu'
import { generateUniqueNumber } from '../helper/commonHelper'
import { Autocomplete } from '@material-ui/lab'
import InputMask from 'react-input-mask'
import { getCompanySettings } from '../store/actions/companySettingsActions'
import TimePicker from 'rc-time-picker'
import dictionary from '../store/dictionary.json'

const CheckboxWithGreenCheck = withStyles({
  root: {
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 5,
        top: 5,
        height: 14,
        width: 14,
        position: 'absolute',
        backgroundColor: '#1e4ca1',
        zIndex: 0,
      },
    },
  },
  checked: {},
})(Checkbox)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  primary: {
    main: '#1e4ca1',
  },
  disabledColor: {
    background: '#E8E8E8',
  },
  imgSize: {
    width: '20px',
    marginRight: '10px',
    cursor: 'pointer',
  },
}))

const AddUsers = (props) => {
  const { enqueueSnackbar, error, loading } = props

  const textRef = useRef()

  const classes = useStyles()
  const { user_Details } = useSelector((state) => state.user)
  const { company_List } = useSelector((state) => state.company)
  const dispatch = useDispatch()

  const history = useHistory()

  const [pageLoading, setPageLoading] = React.useState(true)
  const [companyValue, setCompanyValue] = useState({})

  const [values, setValues] = React.useState({
    lastName: '',
    firstName: '',
    displayName: '',
    email: '',
    countryCode: '',
    cell: '',
    note: '',
    isSentinel: false,
    isFob: false,
    isInstaller: false,
    isInactive: false,
    isSOInterrupt: true,
  })

  const [formValidation, setFormValidation] = React.useState({
    lastName: '',
    firstName: '',
    displayName: '',
    countryCode: '',
    cell: '',
  })

  const [defaultCountryCodeSet, updateDefaultCountryCodeSet] =
    React.useState(false)
  const setDefaultCountryCode = (state) => {
    if (
      !defaultCountryCodeSet &&
      !values.countryCode &&
      state.companySettings?.companySettingsDetails?.default_country_code
    ) {
      updateDefaultCountryCodeSet(true)
      setValues({
        ...values,
        countryCode:
          state.companySettings?.companySettingsDetails?.default_country_code,
      })
    }
  }
  const { companySettingsDetails } = useSelector((state) => {
    setDefaultCountryCode(state)
    return state.companySettings
  })

  const handleValidation = () => {
    let isError = false
    const formerr = { ...formValidation }

    if (!values.lastName) {
      isError = true
      formerr.lastName =
        dictionary[localStorage.getItem('language')].last_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.firstName) {
      isError = true
      formerr.firstName =
        dictionary[localStorage.getItem('language')].first_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.displayName) {
      isError = true
      formerr.displayName =
        dictionary[localStorage.getItem('language')].display_name_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.email) {
      isError = true
      formerr.email =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (values.email && !validator.isEmail(values.email)) {
      isError = true
      formerr.email =
        dictionary[localStorage.getItem('language')].email_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].invalid_tooltip
      setFormValidation(formerr)
    }

    if (!values.countryCode) {
      isError = true
      formerr.countryCode =
        dictionary[localStorage.getItem('language')].country_code_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    }

    if (!values.cell) {
      isError = true
      formerr.cell =
        dictionary[localStorage.getItem('language')].cell_number_field_name +
        ' ' +
        dictionary[localStorage.getItem('language')].required_field_tooltip
      setFormValidation(formerr)
    } else if (values.cell.length < 10) {
      isError = true
      formerr.cell =
        dictionary[localStorage.getItem('language')].cell_number_invalid
      setFormValidation(formerr)
    }

    return isError
  }

  const handleChange = (prop) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    setValues({ ...values, [prop]: value })
    const formerr = { ...formValidation }
    formerr[event.target.name] = null
    setFormValidation(formerr)
  }

  const handleBlur = () => {
    const firstName = values.firstName
    const lastName = values.lastName
    const displayName = lastName.concat(', ', firstName)
    const formerr = { ...formValidation }
    formerr.displayName = null
    setFormValidation(formerr)
    setValues({ ...values, displayName: displayName })
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    if (handleValidation()) return false

    const uData = {
      firstName: values.firstName,
      lastName: values.lastName,
      displayName: values.displayName,
      emailId: values.email,
      countryCode: values.countryCode,
      phoneNumer: values.cell,
      userNotes: values.note,
      isSentinel: values.isSentinel === true ? '1' : '0',
      isSuperAdmin: values.isSuperAdmin === true ? '1' : '0',
      isInstaller: values.isInstaller === true ? '1' : '0',
      isFob: values.isFob === true ? '1' : '0',
      isInactive: values.isInactive === true ? '1' : '0',
      isSOInterrupt: values.isSOInterrupt === true ? '1' : '0',
    }

    dispatch(registerUser(uData, history))
  }

  useEffect(() => {
    //dispatch(companyListForUsers())
    dispatch(getCompanySettings())
  }, [dispatch])

  useEffect(() => {
    error && enqueueSnackbar(error, { variant: 'error' })
  }, [error])

  const [value, setValue] = React.useState('female')

  const handleChangeCheckboxValue = (key, value) => {
    if (key === 'isSuperAdmin' && value) {
      setValues({ ...values, [key]: value, 'isSentinel': true })
    } else {
      setValues({ ...values, [key]: value })
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  const fieldSetStyle = {
    minWidth: 'auto',
    margin: 'initial',
    padding: '8px',
    border: '2px solid #1e4ca1',
    borderRadius: '20px',
  }
  const fieldSetLegendStyle = {
    fontSize: '0.7rem',
    lineHeight: 'inherit',
    display: 'inherit',
    width: 'inherit',
    maxWidth: 'inherit',
    marginBottom: 'inherit',
    padding: '5px',
    whiteSpace: 'inherit',
    color: 'inherit',
    textAlign: 'center',
    color: 'var(--primary)',
    fontWeight: 'bold',
    margin: 'auto',
  }

  const ACCESS_TYPE = {
    ONE_TIME_ACCESS: 'one_time_access',
    CUSTOM_ACCESS: 'custom_access',
    FULL_ACCESS: 'full_access',
  }

  const isEndDateDisabled = true
  const isStartDateDisabled = true
  const isAllDayDisabled = true
  const isNeverEndDisabled = true
  const isTimeDisabled = true
  const isZoneDisabled = true
  const isWeekDisabled = true
  const isFullAccessDisabled = true
  const isCustomAccessDisabled = true
  const isOneTimeAccessDisabled = true

  return (
    <div>
      {pageLoading ? <Loader /> : null}

      <Submenu />

      <div className="Contborder">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: 0 }}>
              <div
                className="row align-items-center"
                style={{ padding: '12px 25px 0 0' }}
              >
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <Link class="btn btn-primary float-right" to="/users">
                    {
                      dictionary[localStorage.getItem('language')]
                        .user_list_button
                    }
                  </Link>
                </div>
              </div>
              <div className="card-header">
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        id="lname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .last_name_field_name
                        }
                        variant="outlined"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        error={!!formValidation.lastName}
                        helperText={formValidation.lastName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="fname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .first_name_field_name
                        }
                        variant="outlined"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur}
                        error={!!formValidation.firstName}
                        helperText={formValidation.firstName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="dname"
                        fullWidth
                        label={
                          dictionary[localStorage.getItem('language')]
                            .display_name_field_name
                        }
                        variant="outlined"
                        name="displayName"
                        value={values.displayName}
                        onChange={handleChange('displayName')}
                        error={!!formValidation.displayName}
                        helperText={formValidation.displayName}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <div class="row">
                        <div className="col-md-6">
                          <TextField
                            id="email"
                            fullWidth
                            label={
                              dictionary[localStorage.getItem('language')]
                                .email_field_name
                            }
                            variant="outlined"
                            name="email"
                            value={values.email}
                            onChange={handleChange('email')}
                            error={!!formValidation.email}
                            helperText={formValidation.email}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4">
                              <TextField
                                id="countryCode"
                                label={
                                  dictionary[localStorage.getItem('language')]
                                    .country_code_field_name
                                }
                                variant="outlined"
                                name="countryCode"
                                value={values.countryCode}
                                onChange={(e) => {
                                  if (
                                    e.target.value === '' ||
                                    /^[0-9\b]+$/.test(e.target.value)
                                  ) {
                                    handleChange('countryCode')(e)
                                  }
                                }}
                                error={!!formValidation.countryCode}
                                helperText={formValidation.countryCode}
                                inputProps={{
                                  inputMode: 'numeric',
                                  maxLength: 3,
                                  pattern: '[0-9]{1,3}',
                                }}
                              />
                            </div>
                            <div className="col-md-8">
                              <InputMask
                                mask="999-999-9999"
                                value={values.cell}
                                onChange={handleChange('cell')}
                              >
                                {(inputProps) => (
                                  <TextField
                                    {...inputProps}
                                    id="cell"
                                    fullWidth
                                    label={
                                      dictionary[
                                        localStorage.getItem('language')
                                      ].cell_number_field_name
                                    }
                                    variant="outlined"
                                    name="cell"
                                    error={!!formValidation.cell}
                                    helperText={formValidation.cell}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <TextField
                            id="usernote"
                            fullWidth
                            label={
                              dictionary[localStorage.getItem('language')]
                                .user_notes_field_name
                            }
                            variant="outlined"
                            name="cell"
                            value={values.note}
                            onChange={handleChange('note')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div
                          className="col-md-7"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <fieldset style={fieldSetStyle}>
                            <legend style={fieldSetLegendStyle}>
                              {
                                dictionary[localStorage.getItem('language')]
                                  .additional_privileges_header
                              }
                            </legend>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].schedule_open_interrupt_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {values.isSOInterrupt ? (
                                    <img
                                      alt="SO Interrupt"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSOInterrupt',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="SO Interrupt"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSOInterrupt',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .schedule_open_interrupt_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].installer_permissions_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {values.isInstaller ? (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isInstaller',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Installer"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isInstaller',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .installer_permissions_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].schedule_open_interrupt_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {values.isSentinel ? (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSentinel',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSentinel',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .sentinel_web_management_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip
                                title={
                                  dictionary[
                                    localStorage.getItem('language')
                                  ].super_admin_tooltip
                                }
                                placement="left"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  className="mb-2"
                                >
                                  {values.isSuperAdmin ? (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/checked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSuperAdmin',
                                          false
                                        )
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt="Sentinel"
                                      src="../assets/img/unchecked.png"
                                      className={classes.imgSize}
                                      onClick={() =>
                                        handleChangeCheckboxValue(
                                          'isSuperAdmin',
                                          true
                                        )
                                      }
                                    />
                                  )}
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .super_admin_option
                                  }
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                className="mb-2"
                              >
                                <a
                                  href="https://support.pac-blu.com/permissions/"
                                  target="_blank"
                                  style={{ textDecoration: 'underline #1e4ca1', fontSize: 'smaller' }}
                                >
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .learn_more_permissions_option
                                  }
                                </a>
                              </Typography>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-md-5">
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <fieldset
                              style={{ ...fieldSetStyle, marginBottom: '10px' }}
                            >
                              <legend style={fieldSetLegendStyle}>
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .deactivate_account_option
                                }
                              </legend>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '-10px',
                                }}
                              >
                                <Switch
                                  style={{ margin: 'auto' }}
                                  color="primary"
                                  checked={values.isInactive}
                                  inputProps={{ 'aria-label': 'primary' }}
                                  name="is_deactive"
                                  onChange={handleChange('isInactive')}
                                />
                              </div>
                            </fieldset>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={handleSubmitClick}
                                style={{
                                  display: props.loading ? 'none' : 'block',
                                }}
                              >
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .save_changes_button
                                }
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                style={{
                                  display: props.loading ? 'block' : 'none',
                                }}
                              >
                                {
                                  dictionary[localStorage.getItem('language')]
                                    .save_changes_button
                                }{' '}
                                &nbsp;
                                <i className="fa fa-spinner fa-spin right" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <Divider
                variant="middle"
                style={{ background: '#1E4CA1', margin: '0 15px 0 15px' }}
              />
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      className="mb-3 text-center"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .assigned_ekeys_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ height: '100%', border: '2px solid #b0b0b0' }}
                      className={classes.disabledColor}
                    >
                      <CardContent></CardContent>
                    </Card>
                  </div>
                  <div className="col-md-8">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      className="mb-3 text-center"
                    >
                      <strong>
                        {
                          dictionary[localStorage.getItem('language')]
                            .modify_ekeys_header
                        }
                      </strong>
                    </Typography>
                    <Card
                      variant="outlined"
                      style={{ border: '2px solid #b0b0b0' }}
                    >
                      <CardContent>
                        <RadioGroup aria-label="accessType" name="accessType">
                          <div className="row">
                            <div className="col">
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  disabled
                                  native
                                  label={
                                    dictionary[localStorage.getItem('language')]
                                      .device_selection
                                  }
                                  inputProps={{
                                    name: 'ekeys',
                                    id: 'outlined-age-native-simple',
                                    className: classes.disabledColor,
                                  }}
                                ></Select>
                              </FormControl>
                            </div>
                          </div>

                          <div
                            className="mt-2"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginBottom: '-1rem',
                            }}
                          >
                            <div style={{ flex: '1', marginRight: -5 }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  control={
                                    <Radio
                                      color="primary"
                                      disabled={isFullAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .full_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={false}
                                      disabled={isCustomAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .custom_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                            <div style={{ flex: '1' }}>
                              <Typography variant="subtitle2">
                                <FormControlLabel
                                  style={{ marginRight: 0 }}
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={false}
                                      disabled={isOneTimeAccessDisabled}
                                    />
                                  }
                                />
                                <strong>
                                  {
                                    dictionary[localStorage.getItem('language')]
                                      .one_time_access_option
                                  }
                                </strong>
                              </Typography>
                            </div>
                          </div>

                          <Divider
                            className="mb-2"
                            style={{ background: '#1E4CA1' }}
                          />

                          <div className="row mt-2 mb-2">
                            <div className="col-md-12">
                              <div className="row pl-2 mr-3 mb-4">
                                <div className="col-md-12">
                                  <div class="row">
                                    <div className="col-md-7">
                                      <Box display="flex">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            disablePast
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].start_date_field_name
                                            }
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            size="small"
                                            value={null}
                                            name="startDate"
                                            disabled={isStartDateDisabled}
                                            InputProps={
                                              isStartDateDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                            className="mr-2"
                                          />

                                          <DatePicker
                                            disablePast
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].end_date_field_name
                                            }
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            value={null}
                                            size="small"
                                            name="endDate"
                                            disabled={isEndDateDisabled}
                                            InputProps={
                                              isEndDateDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                          />
                                        </MuiPickersUtilsProvider>
                                        <Box display="flex">
                                          <FormControlLabel
                                            control={
                                              <CheckboxWithGreenCheck
                                                name="isNeverEnd"
                                                color="primary"
                                              />
                                            }
                                            style={{
                                              marginLeft: 0,
                                              marginRight: -5,
                                              marginBottom: 0,
                                              marginTop: 0,
                                            }}
                                            disabled={isNeverEndDisabled}
                                            InputProps={
                                              isNeverEndDisabled
                                                ? {
                                                    className:
                                                      classes.disabledColor,
                                                  }
                                                : null
                                            }
                                          />
                                          <Typography
                                            variant="subtitle2"
                                            className="mr-4"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <span
                                              style={{
                                                whiteSpace: 'nowrap',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <strong>
                                                {
                                                  dictionary[
                                                    localStorage.getItem(
                                                      'language'
                                                    )
                                                  ].never_expires_option
                                                }
                                              </strong>
                                            </span>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-4 ml-3">
                                  <div class="row">
                                    <FormControl
                                      component="fieldset"
                                      style={{ marginTop: -9 }}
                                    >
                                      <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="top"
                                        className="radioGroup"
                                      >
                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].monday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="mon"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].tuesday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="tue"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].wednesday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="wed"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].thursday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="thu"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].friday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="fri"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].saturday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="sat"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />

                                        <Typography variant="subtitle2">
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].sunday_abbreviation
                                            }
                                          </strong>
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <CheckboxWithGreenCheck
                                              name="sun"
                                              color="primary"
                                              checked={false}
                                            />
                                          }
                                          value={false}
                                          style={{
                                            marginLeft: -32,
                                            marginTop: 18,
                                          }}
                                          disabled={isWeekDisabled}
                                          InputProps={
                                            isWeekDisabled
                                              ? {
                                                  className:
                                                    classes.disabledColor,
                                                }
                                              : null
                                          }
                                        />
                                      </RadioGroup>
                                      <FormHelperText></FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-2 ml-1">
                                  <Box display="flex">
                                    <Box display="flex">
                                      <FormControlLabel
                                        control={
                                          <CheckboxWithGreenCheck
                                            name="isAllDay"
                                            color="primary"
                                          />
                                        }
                                        style={{
                                          marginLeft: -10,
                                          marginRight: -5,
                                          marginBottom: 0,
                                          marginTop: 0,
                                        }}
                                        disabled={isAllDayDisabled}
                                        InputProps={
                                          isAllDayDisabled
                                            ? {
                                                className:
                                                  classes.disabledColor,
                                              }
                                            : null
                                        }
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        className="mr-4"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <span
                                          style={{
                                            whiteSpace: 'nowrap',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <strong>
                                            {
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].all_day_option
                                            }
                                          </strong>
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="inline-flex"
                                      flexDirection="column"
                                      className="mr-2"
                                    >
                                      <Box display="flex">
                                        <TimePicker
                                          placeholder={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].start_time_field_name
                                          }
                                          showSecond={false}
                                          focusOnOpen={true}
                                          format="HH:mm"
                                          disabled={isTimeDisabled}
                                          clearText="Clear"
                                          className="mr-2"
                                        />
                                        <TimePicker
                                          placeholder={
                                            dictionary[
                                              localStorage.getItem('language')
                                            ].end_time_field_name
                                          }
                                          showSecond={false}
                                          focusOnOpen={true}
                                          format="HH:mm"
                                          disabled={isTimeDisabled}
                                          clearText="Clear"
                                        />
                                      </Box>
                                      <FormControl variant="outlined" fullWidth>
                                        <FormHelperText></FormHelperText>
                                      </FormControl>
                                    </Box>
                                    <FormControl variant="outlined" fullWidth>
                                      <Autocomplete
                                        disabled={isZoneDisabled}
                                        className={
                                          isZoneDisabled
                                            ? classes.disabledColor
                                            : null
                                        }
                                        size="small"
                                        style={{ width: 270 }}
                                        id="combo-box-demo"
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={
                                              dictionary[
                                                localStorage.getItem('language')
                                              ].time_zone_field_name
                                            }
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                      <FormHelperText></FormHelperText>
                                    </FormControl>
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>

                          <Divider
                            className="mb-2"
                            style={{ background: '#1E4CA1' }}
                          />

                          <div className="mt-2">
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              disabled
                            >
                              {
                                dictionary[localStorage.getItem('language')]
                                  .assign_ekey_button
                              }
                            </Button>
                          </div>
                        </RadioGroup>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
  }
}

AddUsers.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

// export default (withSnackbar(AddUsers));
export default connect(mapStateToProps)(withSnackbar(AddUsers))
