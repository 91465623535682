import * as actionTypes from './actionTypes'
import Axios, { setHeaders } from '../../helper/axios'
import {
  createActivityLogWithoutLogin,
  createActivityLog,
} from '../../helper/commonHelper'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const authSuccess = (user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user,
  }
}

export const alertSuccess = (alert) => {
  return {
    type: actionTypes.ALERT_SUCCESS,
    alert,
  }
}

export const forgotSuccess = (message) => {
  return {
    type: actionTypes.FORGOT_SUCCESS,
    success_message: message,
  }
}

export const resetPasswordExpiredSuccess = (message) => {
  return {
    type: actionTypes.RESET_PASSWORD_EXPIRED,
    success_message: message,
  }
}

export const isLoading = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const Success = (response) => {
  return response
}

export const Fail = (error, errorCode) => {
  return {
    type: actionTypes.AUTHERROR,
    error: error,
    errorCode: errorCode,
  }
}

export const SuccessMessage = (message) => {
  return {
    type: actionTypes.SUCCESS_MESSAGE_AUTH,
    success_message: message,
  }
}

export const permissionDetailsSuccess = (details) => {
  return {
    type: actionTypes.PERMISSION_DETAILS,
    payload: details,
  }
}

export const token = () => {
  return localStorage.getItem('token')
}

export const auth = (username, password, companyId, language) => {
  return (dispatch) => {
    dispatch(authStart())
    const authData = {
      userName: username,
      password: password,
      companyId: companyId,
      language: language,
    }
    const activityLogData = {
      userName: username,
      companyId: companyId,
    }
    setHeaders()
    Axios.post('/user/login', authData)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token)
          // localStorage.setItem("userData", JSON.stringify(response.data.payload));
          localStorage.setItem('displayName', response.data.payload.dispay_name)
          localStorage.setItem('logedUserId', response.data.payload.id)
          localStorage.setItem('userRole', response.data.payload.role_id)
          localStorage.setItem('tempCompId', response.data.payload.comp_id)
          localStorage.setItem(
            'companyType',
            response.data.payload.company_type
          )
          localStorage.setItem('userEmail', response.data.payload.e_mail)
          localStorage.setItem(
            'companyName',
            response.data.payload.company.company_name
          )

          // Get and parse the company details from local storage
          let companyDetails = JSON.parse(
            localStorage.getItem('companyDetails')
          )
          // If the company details are null, then substitute
          if (companyDetails == null) {
            companyDetails = {}
          }
          // Save the company code as the value to the company name key
          let companyName = response.data.payload.company.company_name
          companyDetails[companyName] = response.data.payload.company_id
          // Save the new company details
          localStorage.setItem('companyDetails', JSON.stringify(companyDetails))

          dispatch(authSuccess(response, response.data, response.data.msg))
          //createActivityLog('User Logs In', '', activityLogData) // create activity log
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
        if (err.response.status === 400) {
          dispatch(Fail(err.response.data.message, err.response.data.errorCode))
        }
        if (err.response.status === 402) {
          localStorage.setItem('phoneEnd', err.response.data.phoneNumber)
          dispatch(Fail(err.response.data.message, err.response.data.errorCode))
        }
        dispatch(Fail(err.response.data.message))
      })
  }
}

export const logoutAction = (token) => {
  return (dispatch) => {
    createActivityLog('User Logs Out', '', '', '', token) // create activity log
  }
}

export const permissions = () => {
  return (dispatch) => {
    const authCode = {
      headers: { Authorization: token() },
    }
    const roleId = localStorage.getItem('userRole')
    setHeaders()
    Axios.get('/user/getUserPermissions?roleId=' + roleId, authCode)
      .then((response) => {
        if (response.status === 200) {
          dispatch(permissionDetailsSuccess(response.data.payload))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message, 401))
        }
      })
  }
}

export const resetExpiredPassword = (requestPayload) => {
  return (dispatch) => {
    dispatch(authStart())
    // const authCode = {
    //   headers: { 'Authorization': token() }
    // }

    setHeaders()
    Axios.post('/user/resetExpiredPassword', requestPayload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(resetPasswordExpiredSuccess(response.data.message))
          createActivityLogWithoutLogin(
            'Reset Password',
            '',
            response.data.payload,
            '',
            response.data.payload
          ) // create activity log
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const twoFactorAuthenticate = (requestPayload) => {
  return (dispatch) => {
    dispatch(authStart())

    setHeaders()
    Axios.post('/user/twoFactorAuthenticate', requestPayload)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token)
          // localStorage.setItem("userData", JSON.stringify(response.data.payload));
          localStorage.setItem('displayName', response.data.payload.dispay_name)
          localStorage.setItem('logedUserId', response.data.payload.id)
          localStorage.setItem('userRole', response.data.payload.role_id)
          localStorage.setItem('tempCompId', response.data.payload.comp_id)
          localStorage.setItem(
            'companyType',
            response.data.payload.company_type
          )
          localStorage.setItem('userEmail', response.data.payload.e_mail)
          localStorage.setItem(
            'companyName',
            response.data.payload.company.company_name
          )

          // Get and parse the company details from local storage
          let companyDetails = JSON.parse(
            localStorage.getItem('companyDetails')
          )
          // If the company details are null, then substitute
          if (companyDetails == null) {
            companyDetails = {}
          }
          // Save the company code as the value to the company name key
          let companyName = response.data.payload.company.company_name
          companyDetails[companyName] = response.data.payload.company_id
          // Save the new company details
          localStorage.setItem('companyDetails', JSON.stringify(companyDetails))

          dispatch(authSuccess(response, response.data, response.data.msg))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const forgotpassword = (username, companyId) => {
  return (dispatch) => {
    dispatch(authStart())
    const authData = {
      userName: username,
      companyId: companyId,
    }

    setHeaders()
    Axios.post('/user/webForgotPassword', authData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(forgotSuccess(response.data.message))
          createActivityLogWithoutLogin(
            'Forgot Password Link Sent',
            '',
            authData,
            '',
            authData
          )
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}

export const resetPass = (password, confirmPassword, uid, history) => {
  return (dispatch) => {
    dispatch(authStart())
    const authData = {
      password: password,
      uid: uid,
    }
    if (password !== confirmPassword) {
      dispatch(Fail('The two passwords must match'))
    } else {
      setHeaders()
      Axios.post('/user/webReSetPassword', authData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(forgotSuccess(response.data.message))
            createActivityLogWithoutLogin(
              'Password Reset via Link',
              '',
              response.data,
              '',
              authData
            )
            if (response.data.hasWebAccess == '1') {
              history.push({
                pathname: '/nexgen',
                isLoaderRequired: false,
              })
            } else {
              window.location.href = "https://support.pac-blu.com/download-nexgen"
            }
          } else {
            dispatch(Fail(response.data.message))
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(Fail(err.response.data.message))
          }
        })
    }
  }
}

export const resetErrorAndSuccessState = () => {
  return {
    type: actionTypes.REST_ERROR_AND_SUCCESS_STATE,
  }
}

export const getSentinelAlerts = () => {
  return (dispatch) => {
    Axios.get('/user/getSentinelAlerts')
      .then((response) => {
        if (response.status === 200) {
          dispatch(alertSuccess(response, response.data, response.data.msg))
        } else {
          dispatch(Fail(response.data.message))
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(Fail(err.response.data.message))
        }
      })
  }
}
