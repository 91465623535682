import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { FormControl, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  activityLogList,
  activityLogReport,
} from '../store/actions/userActions'
import { withSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../PageLoader'
import Footer from '../footer'
import moment from 'moment'
import Moment from 'react-moment'
import timeZone from 'moment-timezone'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import { CSVLink } from 'react-csv'
import Submenu from '../header_sub_menu'
import { UTCDateTimeFormat } from '../helper/commonHelper'
import dictionary from '../store/dictionary.json'

const headers = [
  { label: 'ID', key: 'log_number' },
  { label: 'Date', key: 'date' },
  { label: 'Time', key: 'time' },
  { label: 'Activity', key: 'activity.name' },
  { label: 'Trigger', key: 'activity.type' },
  { label: 'Triggered By', key: 'by_user' },
  { label: 'User', key: 'target_user' },
  { label: 'Device/Device Group', key: 'target_device' },
]

const columns = [
  /**{
    field: 'ID',
    headerName: 'ID',
    width: 75,
    renderHeader: () => (
      <strong>{dictionary[localStorage.getItem('language')].id_header}</strong>
    ),
    renderCell: (rows) => rows.row.company_log_id,
  },**/
  {
    field: 'Date',
    headerName: 'Date',
    width: 125,
    filterable: false,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].date_header}
      </strong>
    ),
    renderCell: (rows) => (
      <Link>{moment(rows.row.activity_timestamp).format('MM/DD/YYYY')}</Link>
    ),
  },
  {
    field: 'Time',
    headerName: 'Time',
    width: 100,
    filterable: false,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].time_header}
      </strong>
    ),
    renderCell: (rows) =>
      moment(rows.row.activity_timestamp).format('HH:mm:ss'),
  },
  {
    field: 'Activity',
    headerName: 'Activity',
    width: 250,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].activity_header}
      </strong>
    ),
    renderCell: (rows) => (
      // If the activity is a sensor log, then show the saved sensor log nickname if it exists. If it doesn't, use current device sensor name
      // If the sensor name doesn't exist, use the default activity name
      <Link>
        {rows.row.activity.name == 'Sensor 1 Opened'
          ? rows.row.details_json.device &&
            rows.row.details_json.device.sensor1_open_name
            ? rows.row.details_json.device.sensor1_open_name
            : rows.row.target_device && rows.row.target_device.sensor1_open_name
            ? rows.row.target_device.sensor1_open_name
            : rows.row.activity.name
          : rows.row.activity.name == 'Sensor 1 Closed'
          ? rows.row.details_json.device &&
            rows.row.details_json.device.sensor1_close_name
            ? rows.row.details_json.device.sensor1_close_name
            : rows.row.target_device &&
              rows.row.target_device.sensor1_close_name
            ? rows.row.target_device.sensor1_close_name
            : rows.row.activity.name
          : rows.row.activity.name == 'Sensor 2 Opened'
          ? rows.row.details_json.device &&
            rows.row.details_json.device.sensor2_open_name
            ? rows.row.details_json.device.sensor2_open_name
            : rows.row.target_device && rows.row.target_device.sensor2_open_name
            ? rows.row.target_device.sensor2_open_name
            : rows.row.activity.name
          : rows.row.activity.name == 'Sensor 2 Closed'
          ? rows.row.details_json.device &&
            rows.row.details_json.device.sensor2_close_name
            ? rows.row.details_json.device.sensor2_close_name
            : rows.row.target_device &&
              rows.row.target_device.sensor2_close_name
            ? rows.row.target_device.sensor2_close_name
            : rows.row.activity.name
          : rows.row.activity.name}
      </Link>
    ),
  },
  {
    field: 'Trigger',
    headerName: 'Trigger',
    width: 100,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].trigger_header}
      </strong>
    ),
    renderCell: (rows) => <Link>{rows.row.activity.type}</Link>,
  },
  {
    field: 'Triggered By',
    headerName: 'Triggered By',
    width: 200,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].triggered_by_header}
      </strong>
    ),
    renderCell: (rows) =>
      rows.row.created_by_user ? (
        <Link>{rows.row.created_by_user.dispay_name}</Link>
      ) : (
        '-'
      ),
  },
  {
    field: 'User',
    headerName: 'User',
    width: 200,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].user_header}
      </strong>
    ),
    renderCell: (rows) =>
      rows.row.target_user ? (
        <Link>{rows.row.target_user.dispay_name}</Link>
      ) : (
        '-'
      ),
  },
  {
    field: 'Device/Device Group',
    headerName: 'Device/Device Group',
    width: 250,
    renderHeader: () => (
      <strong>
        {dictionary[localStorage.getItem('language')].device_header}
      </strong>
    ),
    renderCell: (rows) => {
      return rows.row.target_device ? (
        <Link>{rows.row.target_device.display_name}</Link>
      ) : rows.row.target_device_group ? (
        <Link>{rows.row.target_device_group.display_name}</Link>
      ) : (
        '-'
      )
    },
  },
]

const operationType = ['All', 'Sentinel', 'App', 'Device']

const Activity = (props) => {
  var { enqueueSnackbar, error, success, loading, pageLoading } = props
  const dispatch = useDispatch()
  var { activityLog_List } = useSelector((state) => state.user)
  var { activityLog_Report } = useSelector((state) => state.user)
  const location = useLocation()
  const history = useHistory()
  var waitingForDownload = false

  const [isLoad, setIsLoad] = useState(false)

  setTimeout(function () {
    setIsLoad(true)
  }, 0)

  const [activityLog_List_Updated, setActivityLog_List_Updated] = useState([])

  useEffect(() => {
    setActivityLog_List_Updated(activityLog_List.rows)
  }, [activityLog_List])

  useEffect(() => {
    if (loader) {
      const blob = new Blob([[activityLog_Report]], {
        type: 'text/csv',
      })
      const a = document.createElement('a')
      a.download = 'activity_report.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()

      setloader(false)
    }
  }, [activityLog_Report])

  const [values, setValues] = React.useState({
    filterOperationType: operationType[0],
    filterFromDate: '',
    filterToDate: '',
    id: '',
    type: '',
    page: 0,
    pageSize: 100,
  })

  const [activityList, setActivityList] = React.useState([])
  const [loader, setloader] = React.useState(false)
  const [pages, setPages] = React.useState(0)

  const handleChangeSelection = (event, type) => {
    if (event) {
      if (type === 'type') {
        setValues({
          ...values,
          filterOperationType: event == 'All' ? 'All' : event,
        })
      }
    }
  }

  const handleChangeSchedule = (event, type) => {
    if (event) {
      if (type === 'fromDate') {
        setValues({ ...values, filterFromDate: event })
      } else if (type === 'toDate') {
        setValues({ ...values, filterToDate: event })
      } else if (type === 'fromTime') {
        setValues({ ...values, filterFromTime: event })
      } else if (type === 'toTime') {
        setValues({ ...values, filterToTime: event })
      }
    }
  }

  const handleFilter = (type) => {
    activityLog_List = []

    if (type === 'filter') {
      // Get the timestamp for the start and end of the day adjusted to current timezone format
      if (values.filterFromDate) {
        values.filterFromDate = formatTimestamp(
          moment(values.filterFromDate).startOf('day')
        )
      }
      if (values.filterToDate) {
        values.filterToDate = formatTimestamp(
          moment(values.filterToDate).endOf('day')
        )
      }

      setPages(0)
      dispatch(activityLogList(values, false))
    } else if (type === 'rest') {
      history.push({ state: null })
      let reset = { ...values }
      reset.filterOperationType = 'All'
      reset.filterFromDate = ''
      reset.filterToDate = ''
      reset.filterFromTime = ''
      reset.filterToTime = ''
      reset.type = ''
      reset.id = ''
      reset.additionalFilter = null
      setValues(reset)
      dispatch(activityLogList({}, false))
    }
  }

  const downloadCSV = () => {
    setloader(true)

    let reportData = {...values, tz: moment.tz.guess()}
    activityLog_Report = []
    dispatch(activityLogReport(reportData, false))
  }

  const currentlySelected = (GridCellParams) => {
    const action = GridCellParams.colDef.field
    const row = GridCellParams.row

    if (action == 'Date') {
      // Get the timestamp for the start and end of the day adjusted to current timezone format
      let fDate = formatTimestamp(moment(row.activity_timestamp).startOf('day'))
      let tDate = formatTimestamp(moment(row.activity_timestamp).endOf('day'))

      // Set the filter values
      values.filterFromDate = fDate
      values.filterToDate = tDate
      // Remove the from and to time values
      values.filterFromTime = ''
      values.filterToTime = ''

      history.push({
        state: {
          filterFromDate: fDate,
          filterToDate: tDate,
          filterFromTime: '',
          filterToTime: '',
        },
      })
    } else if (action == 'Trigger') {
      values.filterOperationType = row.activity.type

      history.push({
        state: { filterOperationType: row.activity.type },
      })
    } else if (action == 'Triggered By') {
      values.id = row.created_by_user_id
      values.type = 'Triggered By'

      history.push({
        state: { id: row.created_by_user_id, type: 'Triggered By' },
      })
    } else if (action == 'User') {
      values.id = row.target_user_id
      values.type = 'User'

      history.push({
        state: { id: row.target_user_id, type: 'User' },
      })
    } else if (action == 'Device/Device Group' && row.target_device_id) {
      values.id = row.target_device_id
      values.type = 'Device'

      history.push({
        state: { id: row.target_device_id, type: 'Device' },
      })
    } else if (action == 'Device/Device Group' && row.target_device_group_id) {
      values.id = row.target_device_group_id
      values.type = 'Device Group'

      history.push({
        state: { id: row.target_device_group_id, type: 'Device Group' },
      })
    } else if (action == 'Activity') {
      if (row.id) {
        history.push({
          pathname: 'activityDetails',
          state: { details: row },
        })
      }
    }
  }

  const formatTimestamp = (timestamp) => {
    let tz = moment.tz.guess()
    let dt = moment(timestamp).format('YYYY-MM-DD HH:mm:ss')

    dt = moment.tz(dt, tz).format()
    return dt
  }

  const handleFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0] && filterModel.items[0].columnField) {
      if (
        filterModel.items[0].value ||
        filterModel.items[0].operatorValue == 'isEmpty' ||
        filterModel.items[0].operatorValue == 'isNotEmpty'
      ) {
        values.additionalFilter = filterModel.items[0]
        setPages(0)
        dispatch(activityLogList(values, false))
      }
    }
  })

  const handleSortChange = React.useCallback((sortModel) => {
    values.sort = sortModel[0]
    setPages(0)
    dispatch(activityLogList(values, false))
  })

  const handlePageChange = React.useCallback((page) => {
    values.page = page
    setPages(0)
    dispatch(activityLogList(values, false))
  })

  const handlePageSizeChange = React.useCallback((pageSize) => {
    values.pageSize = pageSize
    setPages(0)
    dispatch(activityLogList(values, false))
  })

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    var restFilters = {
      filterOperationType: 'All',
      filterFromDate: '',
      filterToDate: '',
    }
    if (location.state) {
      restFilters.id = location.state.id
      restFilters.type = location.state.type
      if (location.state.filterOperationType) {
        restFilters.filterOperationType = location.state.filterOperationType
      }
    }
    if (values.filterFromDate != '' && values.filterToDate != '') {
      restFilters.filterFromDate = values.filterFromDate
      restFilters.filterToDate = values.filterToDate
    }
    setPages(0)

    dispatch(activityLogList(restFilters, false))
  }, [dispatch, location.state])

  // if(isFilter==1){
  //    loading = true;

  //    setTimeout(function() {
  //     setIsLoad(true);
  //   },5)

  // }

  return (
    <div>
      {pageLoading ? <Loader /> : null}
      <Submenu />
      <div id="Contborder" className="Contborder">
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="card" style={{ marginBottom: 0, height: '100%' }}>
              <div className="row align-items-center mt-3 ml-2 pr-4">
                <div className="col-lg-8">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label={
                        dictionary[localStorage.getItem('language')]
                          .from_date_field_name
                      }
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={
                        values.filterFromDate ? values.filterFromDate : null
                      }
                      size="small"
                      name="startDate"
                      onChange={(value) =>
                        handleChangeSchedule(value, 'fromDate')
                      }
                      className="mr-2"
                      style={{ width: 150 }}
                    />

                    <DatePicker
                      label={
                        dictionary[localStorage.getItem('language')]
                          .to_date_field_name
                      }
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      value={values.filterToDate ? values.filterToDate : null}
                      size="small"
                      name="endDate"
                      onChange={(value) =>
                        handleChangeSchedule(value, 'toDate')
                      }
                      className="mr-2"
                      style={{ width: 150 }}
                    />
                  </MuiPickersUtilsProvider>

                  <TimePicker
                    label={
                      dictionary[localStorage.getItem('language')]
                        .from_time_field_name
                    }
                    placeholder={
                      dictionary[localStorage.getItem('language')]
                        .from_time_field_name
                    }
                    focusOnOpen={true}
                    format="HH:mm:ss"
                    value={values.filterFromTime ? values.filterFromTime : null}
                    onChange={(value) =>
                      handleChangeSchedule(value, 'fromTime')
                    }
                    clearText="Clear"
                    className="mr-2"
                  />
                  <TimePicker
                    placeholder={
                      dictionary[localStorage.getItem('language')]
                        .to_time_field_name
                    }
                    focusOnOpen={true}
                    format="HH:mm:ss"
                    value={values.filterToTime ? values.filterToTime : null}
                    onChange={(value) => handleChangeSchedule(value, 'toTime')}
                    clearText="Clear"
                    className="mr-2"
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => handleFilter('filter')}
                    disabled={
                      (values.filterFromDate && values.filterToDate) ||
                      (values.filterFromTime && values.filterToTime)
                        ? false
                        : true
                    }
                  >
                    {dictionary[localStorage.getItem('language')].filter_button}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleFilter('rest')}
                    disabled={
                      values.filterFromDate ||
                      values.filterToDate ||
                      values.filterFromTime ||
                      values.filterToTime ||
                      values.type ||
                      (values.filterOperationType &&
                        values.filterOperationType != 'All') ||
                      values.additionalFilter
                        ? false
                        : true
                    }
                  >
                    {
                      dictionary[localStorage.getItem('language')]
                        .remove_filter_button
                    }
                  </Button>
                </div>
                <div className="col-lg-4">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => downloadCSV()}
                    style={{
                      display: loader ? 'none' : 'block',
                      float: 'right',
                    }}
                  >
                    {
                      dictionary[localStorage.getItem('language')]
                        .download_report_button
                    }
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      display: loader ? 'block' : 'none',
                      float: 'right',
                    }}
                  >
                    {
                      dictionary[localStorage.getItem('language')]
                        .download_report_button
                    }
                    &nbsp;
                    <i className="fa fa-spinner fa-spin right" />
                  </Button>
                </div>
              </div>
              <div className="card-body" style={{ height: 480, width: '100%' }}>
                {activityLog_List_Updated ? (
                  <DataGrid
                    onCellClick={currentlySelected}
                    rows={activityLog_List_Updated}
                    rowCount={activityLog_List.count}
                    columns={columns}
                    loading={loading}
                    disableColumnSelector
                    filterMode="server"
                    sortingMode="server"
                    paginationMode="server"
                    onFilterModelChange={handleFilterChange}
                    onSortModelChange={handleSortChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success,
    pageLoading: state.user.pageLoading,
  }
}

Activity.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withSnackbar(Activity))
